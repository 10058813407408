/**
 * F.R.I.D.A.Y.
 * Female Replacement Intelligent Digital Assistant Youth
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';

import { ClienteBean } from '../model/clienteBean';
import { CorriereBean } from '../model/corriereBean';
import { EmailProviderBean } from '../model/emailProviderBean';
import { FonteBean } from '../model/fonteBean';
import { GenericResultBean } from '../model/genericResultBean';
import { MetodoPagamentoBean } from '../model/metodoPagamentoBean';
import { OrderComponentsBean } from '../model/orderComponentsBean';
import { OrdineBean } from '../model/ordineBean';
import { RequestDeleteOrdineBean } from '../model/requestDeleteOrdineBean';
import { RequestDettaglioOrdineBean } from '../model/requestDettaglioOrdineBean';
import { RequestRicercaArchivioBean } from '../model/requestRicercaArchivioBean';
import { RequestSaveOrderBean } from '../model/requestSaveOrderBean';
import { RequestTrasferimentoGiornalieroBean } from '../model/requestTrasferimentoGiornalieroBean';
import { RequestTrasferimentoMagazzinoBean } from '../model/requestTrasferimentoMagazzinoBean';
import { RequestUpdateEmailProviderBean } from '../model/requestUpdateEmailProviderBean';
import { ResultContrassegnoBean } from '../model/resultContrassegnoBean';
import { ResultDettaglioOrdineBean } from '../model/resultDettaglioOrdineBean';
import { ResultOrdineBean } from '../model/resultOrdineBean';
import { ResultRicercaArchivioBean } from '../model/resultRicercaArchivioBean';
import { ResultRicercaOrdiniArchivioBean } from '../model/resultRicercaOrdiniArchivioBean';
import { RollbackOrdineResultBean } from '../model/rollbackOrdineResultBean';
import { StatoContrassegnoBean } from '../model/statoContrassegnoBean';
import { StatoOrdineBean } from '../model/statoOrdineBean';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { NotaFatturazioneBean } from '../model/notaFatturazioneBean';
import { DettaglioNotaFatturazioneBean } from '../model/dettaglioNotaFatturazioneBean';


@Injectable()
export class OrdineControllerService {

    protected basePath = '';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * chiudi ordini TNT
     * 
     * @param bean bean
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public chiudiOrdiniTNTUsingPOST(bean: RequestTrasferimentoGiornalieroBean, observe?: 'body', reportProgress?: boolean): Observable<GenericResultBean>;
    public chiudiOrdiniTNTUsingPOST(bean: RequestTrasferimentoGiornalieroBean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResultBean>>;
    public chiudiOrdiniTNTUsingPOST(bean: RequestTrasferimentoGiornalieroBean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResultBean>>;
    public chiudiOrdiniTNTUsingPOST(bean: RequestTrasferimentoGiornalieroBean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bean === null || bean === undefined) {
            throw new Error('Required parameter bean was null or undefined when calling chiudiOrdiniTNTUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<GenericResultBean>(`${this.basePath}/api/ailime/ordine/chiudiOrdiniTNT`,
            bean,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * delete order
     * 
     * @param bean bean
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteOrderUsingPOST(bean: RequestDeleteOrdineBean, observe?: 'body', reportProgress?: boolean): Observable<GenericResultBean>;
    public deleteOrderUsingPOST(bean: RequestDeleteOrdineBean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResultBean>>;
    public deleteOrderUsingPOST(bean: RequestDeleteOrdineBean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResultBean>>;
    public deleteOrderUsingPOST(bean: RequestDeleteOrdineBean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bean === null || bean === undefined) {
            throw new Error('Required parameter bean was null or undefined when calling deleteOrderUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<GenericResultBean>(`${this.basePath}/api/ailime/ordine/deleteOrder`,
            bean,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Find all Pending Warehouse
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findAllPendingWarehouseUsingPOST(observe?: 'body', reportProgress?: boolean): Observable<Array<ResultOrdineBean>>;
    public findAllPendingWarehouseUsingPOST(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ResultOrdineBean>>>;
    public findAllPendingWarehouseUsingPOST(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ResultOrdineBean>>>;
    public findAllPendingWarehouseUsingPOST(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<Array<ResultOrdineBean>>(`${this.basePath}/api/ailime/ordine/findAllPendingWarehouse`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get All Corriere
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllCorriereUsingPOST(observe?: 'body', reportProgress?: boolean): Observable<Array<CorriereBean>>;
    public getAllCorriereUsingPOST(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<CorriereBean>>>;
    public getAllCorriereUsingPOST(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<CorriereBean>>>;
    public getAllCorriereUsingPOST(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<Array<CorriereBean>>(`${this.basePath}/api/ailime/ordine/getAllCorriere`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get All Fonte
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllFonteUsingPOST(observe?: 'body', reportProgress?: boolean): Observable<Array<FonteBean>>;
    public getAllFonteUsingPOST(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<FonteBean>>>;
    public getAllFonteUsingPOST(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<FonteBean>>>;
    public getAllFonteUsingPOST(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<Array<FonteBean>>(`${this.basePath}/api/ailime/ordine/getAllFonte`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get All MetodoPagamento
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllMetodoPagamentoUsingPOST(observe?: 'body', reportProgress?: boolean): Observable<Array<MetodoPagamentoBean>>;
    public getAllMetodoPagamentoUsingPOST(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<MetodoPagamentoBean>>>;
    public getAllMetodoPagamentoUsingPOST(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<MetodoPagamentoBean>>>;
    public getAllMetodoPagamentoUsingPOST(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<Array<MetodoPagamentoBean>>(`${this.basePath}/api/ailime/ordine/getAllMetodoPagamento`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get all Pending Magazzino
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllPendingMagazzinoUsingPOST(observe?: 'body', reportProgress?: boolean): Observable<Array<ResultOrdineBean>>;
    public getAllPendingMagazzinoUsingPOST(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ResultOrdineBean>>>;
    public getAllPendingMagazzinoUsingPOST(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ResultOrdineBean>>>;
    public getAllPendingMagazzinoUsingPOST(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<Array<ResultOrdineBean>>(`${this.basePath}/api/ailime/ordine/getAllPendingMagazzino`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get All StatoContrassegno
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllStatoContrassegnoUsingPOST(observe?: 'body', reportProgress?: boolean): Observable<Array<StatoContrassegnoBean>>;
    public getAllStatoContrassegnoUsingPOST(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<StatoContrassegnoBean>>>;
    public getAllStatoContrassegnoUsingPOST(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<StatoContrassegnoBean>>>;
    public getAllStatoContrassegnoUsingPOST(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<Array<StatoContrassegnoBean>>(`${this.basePath}/api/ailime/ordine/getAllStatoContrassegno`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get All StatoOrdine
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAllStatoOrdineUsingPOST(observe?: 'body', reportProgress?: boolean): Observable<Array<StatoOrdineBean>>;
    public getAllStatoOrdineUsingPOST(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<StatoOrdineBean>>>;
    public getAllStatoOrdineUsingPOST(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<StatoOrdineBean>>>;
    public getAllStatoOrdineUsingPOST(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<Array<StatoOrdineBean>>(`${this.basePath}/api/ailime/ordine/getAllStatoOrdine`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get contrassegni da verificare archiviati
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getContrassegniDaVerificareArchiviatiUsingPOST(observe?: 'body', reportProgress?: boolean): Observable<Array<ResultContrassegnoBean>>;
    public getContrassegniDaVerificareArchiviatiUsingPOST(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ResultContrassegnoBean>>>;
    public getContrassegniDaVerificareArchiviatiUsingPOST(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ResultContrassegnoBean>>>;
    public getContrassegniDaVerificareArchiviatiUsingPOST(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<Array<ResultContrassegnoBean>>(`${this.basePath}/api/ailime/ordine/getContrassegniDaVerificareArchiviati`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get contrassegni da verificare
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getContrassegniDaVerificareUsingPOST(observe?: 'body', reportProgress?: boolean): Observable<Array<ResultContrassegnoBean>>;
    public getContrassegniDaVerificareUsingPOST(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ResultContrassegnoBean>>>;
    public getContrassegniDaVerificareUsingPOST(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ResultContrassegnoBean>>>;
    public getContrassegniDaVerificareUsingPOST(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<Array<ResultContrassegnoBean>>(`${this.basePath}/api/ailime/ordine/getContrassegniDaVerificare`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get dettaglio ordine by id
     * 
     * @param bean bean
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDettaglioOrdineByIdUsingPOST(bean: RequestDettaglioOrdineBean, observe?: 'body', reportProgress?: boolean): Observable<ResultDettaglioOrdineBean>;
    public getDettaglioOrdineByIdUsingPOST(bean: RequestDettaglioOrdineBean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResultDettaglioOrdineBean>>;
    public getDettaglioOrdineByIdUsingPOST(bean: RequestDettaglioOrdineBean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResultDettaglioOrdineBean>>;
    public getDettaglioOrdineByIdUsingPOST(bean: RequestDettaglioOrdineBean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bean === null || bean === undefined) {
            throw new Error('Required parameter bean was null or undefined when calling getDettaglioOrdineByIdUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ResultDettaglioOrdineBean>(`${this.basePath}/api/ailime/ordine/getDettaglioOrdineById`,
            bean,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get email providers
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getEmailProvidersUsingPOST(observe?: 'body', reportProgress?: boolean): Observable<Array<EmailProviderBean>>;
    public getEmailProvidersUsingPOST(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EmailProviderBean>>>;
    public getEmailProvidersUsingPOST(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EmailProviderBean>>>;
    public getEmailProvidersUsingPOST(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<Array<EmailProviderBean>>(`${this.basePath}/api/ailime/ordine/getEmailProviders`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Order Components
     * 
     * @param idOrder idOrder
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOrderComponentsUsingPOST(idOrder: number, observe?: 'body', reportProgress?: boolean): Observable<OrderComponentsBean>;
    public getOrderComponentsUsingPOST(idOrder: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OrderComponentsBean>>;
    public getOrderComponentsUsingPOST(idOrder: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OrderComponentsBean>>;
    public getOrderComponentsUsingPOST(idOrder: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (idOrder === null || idOrder === undefined) {
            throw new Error('Required parameter idOrder was null or undefined when calling getOrderComponentsUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<OrderComponentsBean>(`${this.basePath}/api/ailime/ordine/getOrderComponents`,
            idOrder,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Ordine By Id
     * 
     * @param idOrdine idOrdine
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOrdineByIdUsingPOST(idOrdine: number, observe?: 'body', reportProgress?: boolean): Observable<OrdineBean>;
    public getOrdineByIdUsingPOST(idOrdine: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OrdineBean>>;
    public getOrdineByIdUsingPOST(idOrdine: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OrdineBean>>;
    public getOrdineByIdUsingPOST(idOrdine: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (idOrdine === null || idOrdine === undefined) {
            throw new Error('Required parameter idOrdine was null or undefined when calling getOrdineByIdUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<OrdineBean>(`${this.basePath}/api/ailime/ordine/getOrdineById`,
            idOrdine,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Ordini in Lavorazione
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOrdiniInLavorazioneUsingPOST(observe?: 'body', reportProgress?: boolean): Observable<Array<ResultOrdineBean>>;
    public getOrdiniInLavorazioneUsingPOST(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ResultOrdineBean>>>;
    public getOrdiniInLavorazioneUsingPOST(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ResultOrdineBean>>>;
    public getOrdiniInLavorazioneUsingPOST(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<Array<ResultOrdineBean>>(`${this.basePath}/api/ailime/ordine/getOrdiniInLavorazione`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Invio mail ordini magazzino trasferiti
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public invioMailOrdiniMagazzinoTrasferitiUsingPOST(observe?: 'body', reportProgress?: boolean): Observable<string>;
    public invioMailOrdiniMagazzinoTrasferitiUsingPOST(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public invioMailOrdiniMagazzinoTrasferitiUsingPOST(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public invioMailOrdiniMagazzinoTrasferitiUsingPOST(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<string>(`${this.basePath}/api/ailime/ordine/invioMailOrdiniMagazzinoTrasferiti`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Invio mail ordini sito in contrassegno trasferiti
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public invioMailOrdiniSitoContrassegnoTrasferitiUsingPOST(observe?: 'body', reportProgress?: boolean): Observable<GenericResultBean>;
    public invioMailOrdiniSitoContrassegnoTrasferitiUsingPOST(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResultBean>>;
    public invioMailOrdiniSitoContrassegnoTrasferitiUsingPOST(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResultBean>>;
    public invioMailOrdiniSitoContrassegnoTrasferitiUsingPOST(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<GenericResultBean>(`${this.basePath}/api/ailime/ordine/invioMailOrdiniSitoContrassegnoTrasferiti`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Ricerca archivio contrassegni
     * 
     * @param bean bean
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public ricercaArchivioContrassegniUsingPOST(bean: RequestRicercaArchivioBean, observe?: 'body', reportProgress?: boolean): Observable<ResultRicercaArchivioBean>;
    public ricercaArchivioContrassegniUsingPOST(bean: RequestRicercaArchivioBean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResultRicercaArchivioBean>>;
    public ricercaArchivioContrassegniUsingPOST(bean: RequestRicercaArchivioBean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResultRicercaArchivioBean>>;
    public ricercaArchivioContrassegniUsingPOST(bean: RequestRicercaArchivioBean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bean === null || bean === undefined) {
            throw new Error('Required parameter bean was null or undefined when calling ricercaArchivioContrassegniUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ResultRicercaArchivioBean>(`${this.basePath}/api/ailime/ordine/ricercaArchivioContrassegni`,
            bean,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Ricerca ordini archivio
     * 
     * @param bean bean
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public ricercaOrdiniArchivioUsingPOST(bean: RequestRicercaArchivioBean, observe?: 'body', reportProgress?: boolean): Observable<ResultRicercaOrdiniArchivioBean>;
    public ricercaOrdiniArchivioUsingPOST(bean: RequestRicercaArchivioBean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ResultRicercaOrdiniArchivioBean>>;
    public ricercaOrdiniArchivioUsingPOST(bean: RequestRicercaArchivioBean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ResultRicercaOrdiniArchivioBean>>;
    public ricercaOrdiniArchivioUsingPOST(bean: RequestRicercaArchivioBean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bean === null || bean === undefined) {
            throw new Error('Required parameter bean was null or undefined when calling ricercaOrdiniArchivioUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ResultRicercaOrdiniArchivioBean>(`${this.basePath}/api/ailime/ordine/ricercaOrdiniArchivio`,
            bean,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Rollback ordine magazzino
     * 
     * @param ordine ordine
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public rollbackOrdineMagazzinoUsingPOST(ordine: OrdineBean, observe?: 'body', reportProgress?: boolean): Observable<RollbackOrdineResultBean>;
    public rollbackOrdineMagazzinoUsingPOST(ordine: OrdineBean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RollbackOrdineResultBean>>;
    public rollbackOrdineMagazzinoUsingPOST(ordine: OrdineBean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RollbackOrdineResultBean>>;
    public rollbackOrdineMagazzinoUsingPOST(ordine: OrdineBean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (ordine === null || ordine === undefined) {
            throw new Error('Required parameter ordine was null or undefined when calling rollbackOrdineMagazzinoUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<RollbackOrdineResultBean>(`${this.basePath}/api/ailime/ordine/rollbackOrdineMagazzino`,
            ordine,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Salva Ordine
     * 
     * @param ordine ordine
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public salvaOrdineUsingPOST(ordine: RequestSaveOrderBean, observe?: 'body', reportProgress?: boolean): Observable<OrdineBean>;
    public salvaOrdineUsingPOST(ordine: RequestSaveOrderBean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OrdineBean>>;
    public salvaOrdineUsingPOST(ordine: RequestSaveOrderBean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OrdineBean>>;
    public salvaOrdineUsingPOST(ordine: RequestSaveOrderBean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (ordine === null || ordine === undefined) {
            throw new Error('Required parameter ordine was null or undefined when calling salvaOrdineUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<OrdineBean>(`${this.basePath}/api/ailime/ordine/salvaOrdine`,
            ordine,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Save - Update email providers
     * 
     * @param bean bean
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public saveUpdateEmailProviderUsingPOST(bean: EmailProviderBean, observe?: 'body', reportProgress?: boolean): Observable<EmailProviderBean>;
    public saveUpdateEmailProviderUsingPOST(bean: EmailProviderBean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<EmailProviderBean>>;
    public saveUpdateEmailProviderUsingPOST(bean: EmailProviderBean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<EmailProviderBean>>;
    public saveUpdateEmailProviderUsingPOST(bean: EmailProviderBean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bean === null || bean === undefined) {
            throw new Error('Required parameter bean was null or undefined when calling saveUpdateEmailProviderUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<EmailProviderBean>(`${this.basePath}/api/ailime/ordine/saveUpdateEmailProviders`,
            bean,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Save - Update multiple email providers
     * 
     * @param bean bean
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public saveUpdateMultipleEmailProviderUsingPOST(bean: RequestUpdateEmailProviderBean, observe?: 'body', reportProgress?: boolean): Observable<RequestUpdateEmailProviderBean>;
    public saveUpdateMultipleEmailProviderUsingPOST(bean: RequestUpdateEmailProviderBean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RequestUpdateEmailProviderBean>>;
    public saveUpdateMultipleEmailProviderUsingPOST(bean: RequestUpdateEmailProviderBean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RequestUpdateEmailProviderBean>>;
    public saveUpdateMultipleEmailProviderUsingPOST(bean: RequestUpdateEmailProviderBean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bean === null || bean === undefined) {
            throw new Error('Required parameter bean was null or undefined when calling saveUpdateMultipleEmailProviderUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<RequestUpdateEmailProviderBean>(`${this.basePath}/api/ailime/ordine/saveUpdateMultipleEmailProviders`,
            bean,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Save user
     * 
     * @param user user
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public saveUserUsingPOST(user: ClienteBean, observe?: 'body', reportProgress?: boolean): Observable<ClienteBean>;
    public saveUserUsingPOST(user: ClienteBean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ClienteBean>>;
    public saveUserUsingPOST(user: ClienteBean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ClienteBean>>;
    public saveUserUsingPOST(user: ClienteBean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (user === null || user === undefined) {
            throw new Error('Required parameter user was null or undefined when calling saveUserUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ClienteBean>(`${this.basePath}/api/ailime/ordine/saveUser`,
            user,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Nuovo Stato Contrassegno
     * 
     * @param list list
     * @param idStatoContrassegno idStatoContrassegno
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setStatoContrassegniUsingPOST(list: Array<number>, idStatoContrassegno: number, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public setStatoContrassegniUsingPOST(list: Array<number>, idStatoContrassegno: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public setStatoContrassegniUsingPOST(list: Array<number>, idStatoContrassegno: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public setStatoContrassegniUsingPOST(list: Array<number>, idStatoContrassegno: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (list === null || list === undefined) {
            throw new Error('Required parameter list was null or undefined when calling setStatoContrassegniUsingPOST.');
        }

        if (idStatoContrassegno === null || idStatoContrassegno === undefined) {
            throw new Error('Required parameter idStatoContrassegno was null or undefined when calling setStatoContrassegniUsingPOST.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (list) {
            list.forEach((element) => {
                queryParameters = queryParameters.append('list', <any>element);
            })
        }
        if (idStatoContrassegno !== undefined && idStatoContrassegno !== null) {
            queryParameters = queryParameters.set('idStatoContrassegno', <any>idStatoContrassegno);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<boolean>(`${this.basePath}/api/ailime/ordine/setStatoContrassegni`,
            null,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Trasferisci ordini magazzino
     * 
     * @param bean bean
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public trasferisciOrdiniMagazzinoUsingPOST(bean: RequestTrasferimentoMagazzinoBean, observe?: 'body', reportProgress?: boolean): Observable<GenericResultBean>;
    public trasferisciOrdiniMagazzinoUsingPOST(bean: RequestTrasferimentoMagazzinoBean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResultBean>>;
    public trasferisciOrdiniMagazzinoUsingPOST(bean: RequestTrasferimentoMagazzinoBean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResultBean>>;
    public trasferisciOrdiniMagazzinoUsingPOST(bean: RequestTrasferimentoMagazzinoBean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (bean === null || bean === undefined) {
            throw new Error('Required parameter bean was null or undefined when calling trasferisciOrdiniMagazzinoUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<GenericResultBean>(`${this.basePath}/api/ailime/ordine/trasferisciMagazzino`,
            bean,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

        /**
     * Completamento ordini trasferiti
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
        public completamentoOrdiniTrasferitiUsingPOST(observe?: 'body', reportProgress?: boolean): Observable<GenericResultBean>;
        public completamentoOrdiniTrasferitiUsingPOST(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResultBean>>;
        public completamentoOrdiniTrasferitiUsingPOST(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResultBean>>;
        public completamentoOrdiniTrasferitiUsingPOST(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
    
            let headers = this.defaultHeaders;
    
            // to determine the Accept header
            let httpHeaderAccepts: string[] = [
                'application/json'
            ];
            const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            if (httpHeaderAcceptSelected != undefined) {
                headers = headers.set('Accept', httpHeaderAcceptSelected);
            }
    
            // to determine the Content-Type header
            const consumes: string[] = [
                'application/json'
            ];
    
            return this.httpClient.post<GenericResultBean>(`${this.basePath}/api/ailime/ordine/completaOrdiniTrasferiti`,
                null,
                {
                    withCredentials: this.configuration.withCredentials,
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress
                }
            );
        }

            /**
     * Get note fatturazione
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getNoteFatturazioneUsingPOST(observe?: 'body', reportProgress?: boolean): Observable<Array<NotaFatturazioneBean>>;
    public getNoteFatturazioneUsingPOST(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<NotaFatturazioneBean>>>;
    public getNoteFatturazioneUsingPOST(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<NotaFatturazioneBean>>>;
    public getNoteFatturazioneUsingPOST(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.post<Array<NotaFatturazioneBean>>(`${this.basePath}/api/ailime/ordine/getNoteFatturazione`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

        /**
     * Get dettaglio note fatturazione
     * 
     * @param idNotaFatturazione idNotaFatturazione
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
        public getDettaglioNoteFatturazioneUsingPOST(idNotaFatturazione: number, observe?: 'body', reportProgress?: boolean): Observable<Array<DettaglioNotaFatturazioneBean>>;
        public getDettaglioNoteFatturazioneUsingPOST(idNotaFatturazione: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<DettaglioNotaFatturazioneBean>>>;
        public getDettaglioNoteFatturazioneUsingPOST(idNotaFatturazione: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<DettaglioNotaFatturazioneBean>>>;
        public getDettaglioNoteFatturazioneUsingPOST(idNotaFatturazione: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
    
            if (idNotaFatturazione === null || idNotaFatturazione === undefined) {
                throw new Error('Required parameter idNotaFatturazione was null or undefined when calling getDettaglioNoteFatturazioneUsingPOST.');
            }
    
            let headers = this.defaultHeaders;
    
            // to determine the Accept header
            let httpHeaderAccepts: string[] = [
                'application/json'
            ];
            const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            if (httpHeaderAcceptSelected != undefined) {
                headers = headers.set('Accept', httpHeaderAcceptSelected);
            }
    
            // to determine the Content-Type header
            const consumes: string[] = [
                'application/json'
            ];
            const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
            if (httpContentTypeSelected != undefined) {
                headers = headers.set('Content-Type', httpContentTypeSelected);
            }
    
            return this.httpClient.post<Array<DettaglioNotaFatturazioneBean>>(`${this.basePath}/api/ailime/ordine/getDettaglioNoteFatturazione`,
                idNotaFatturazione,
                {
                    withCredentials: this.configuration.withCredentials,
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress
                }
            );
        }

            /**
     * Salva dettaglio note fatturazione
     * 
     * @param dettagli dettagli
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public salvaDettaglioNoteFatturazioneUsingPOST(dettagli: Array<DettaglioNotaFatturazioneBean>, observe?: 'body', reportProgress?: boolean): Observable<GenericResultBean>;
    public salvaDettaglioNoteFatturazioneUsingPOST(dettagli: Array<DettaglioNotaFatturazioneBean>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResultBean>>;
    public salvaDettaglioNoteFatturazioneUsingPOST(dettagli: Array<DettaglioNotaFatturazioneBean>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResultBean>>;
    public salvaDettaglioNoteFatturazioneUsingPOST(dettagli: Array<DettaglioNotaFatturazioneBean>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (dettagli === null || dettagli === undefined) {
            throw new Error('Required parameter dettagli was null or undefined when calling salvaDettaglioNoteFatturazioneUsingPOST.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<GenericResultBean>(`${this.basePath}/api/ailime/ordine/salvaDettaglioNoteFatturazione`,
            dettagli,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

        /**
     * Ottieni totale note di fatturazione
     * 
     * @param idNoteFatturazioni idNoteFatturazioni
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
        public ottieniTotaleNoteFatturazioneUsingPOST(idNoteFatturazioni: Array<number>, observe?: 'body', reportProgress?: boolean): Observable<number>;
        public ottieniTotaleNoteFatturazioneUsingPOST(idNoteFatturazioni: Array<number>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
        public ottieniTotaleNoteFatturazioneUsingPOST(idNoteFatturazioni: Array<number>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
        public ottieniTotaleNoteFatturazioneUsingPOST(idNoteFatturazioni: Array<number>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
    
            if (idNoteFatturazioni === null || idNoteFatturazioni === undefined) {
                throw new Error('Required parameter idNoteFatturazioni was null or undefined when calling ottieniTotaleNoteFatturazioneUsingPOST.');
            }
    
            let headers = this.defaultHeaders;
    
            // to determine the Accept header
            let httpHeaderAccepts: string[] = [
                'application/json'
            ];
            const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            if (httpHeaderAcceptSelected != undefined) {
                headers = headers.set('Accept', httpHeaderAcceptSelected);
            }
    
            // to determine the Content-Type header
            const consumes: string[] = [
                'application/json'
            ];
            const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
            if (httpContentTypeSelected != undefined) {
                headers = headers.set('Content-Type', httpContentTypeSelected);
            }
    
            return this.httpClient.post<number>(`${this.basePath}/api/ailime/ordine/ottieniTotaleNoteFatturazione`,
                idNoteFatturazioni,
                {
                    withCredentials: this.configuration.withCredentials,
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress
                }
            );
        }
}
